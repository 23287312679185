import config from "config";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserRouter } from "react-router-dom";

// router
import Router from "router/Router";
import ApplicationLayout from "components/ApplicationLayout";

// Initialize css files
import "./css.ts";

import { FlashContextWrapper } from "store/flash";
import { AuthContextProviderWrapper } from "store/auth";
import { ProfileContext, ProfileContextProviderWrapper } from "store/profile";
import { CollectionsProviderWrapper } from "store/collections";
import { FeatureFlagsContextWrapper } from "store/feature-flags";
import { FiltersAndPaginationProviderWrapper } from "store/filters";

const App = () => (
  <BrowserRouter>
    <AuthContextProviderWrapper>
      <ProfileContextProviderWrapper>
        <CollectionsProviderWrapper>
          <ProfileContext.Consumer>
            {([profileData]) => (
              <FiltersAndPaginationProviderWrapper
                key={profileData?.selectedProfile?.profile_id}
              >
                <FlashContextWrapper>
                  <FeatureFlagsContextWrapper>
                    <ApplicationLayout>
                      <Router />
                    </ApplicationLayout>
                  </FeatureFlagsContextWrapper>
                </FlashContextWrapper>
              </FiltersAndPaginationProviderWrapper>
            )}
          </ProfileContext.Consumer>
        </CollectionsProviderWrapper>
      </ProfileContextProviderWrapper>
    </AuthContextProviderWrapper>
  </BrowserRouter>
);

if (process.env.REACT_APP_NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://5abb3bc3d5dd40ad9d1cd04ece736ddf@o373969.ingest.sentry.io/5191325",
    environment: config.appEnv,
  });
}

// render react DOM
ReactDOM.render(<App />, document.getElementById("root"));
