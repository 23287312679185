import Dayjs from "dayjs";
import { Menu, Dropdown } from "antd";
import { useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { CaretDownFilled } from "@ant-design/icons";

import DatePicker from "components/DatePicker";
import ToggleOffCanvas from "components/ToggleSidebarMenu";
import DoubleRangeDatePicker from "components/DoubleRangeDatePicker";

// store
import { ProfileContext } from "store/profile";
import { AuthContext } from "store/auth";

import { AmazonIcon, InstacartIcon } from "components/Icons";
import _ from "lodash";

interface TopbarProps {
  onSidebarMenuToggle: () => void;
}

const Topbar = ({ onSidebarMenuToggle }: TopbarProps) => {
  const [
    {
      selectedProfile,
      selectedAccount,
      profiles,
      accounts,
      range,
      comparedToRange,
    },
    ,
    setSelectedProfile,
    setRanges,
    setSelectedAccount,
  ] = useContext(ProfileContext);
  const [, , setLogout] = useContext(AuthContext);
  const location = useLocation();

  const isDashboardPage = !!location.pathname.match(/dashboard/);

  return (
    <div className="w-full flex items-center justify-between bg-page-bg h-16 px-4">
      <div className="flex items-center h-full">
        <ToggleOffCanvas onClick={onSidebarMenuToggle} />

        <Dropdown
          // overlayClassName="max-h-screen overflow-y-auto shadow-lg"
          trigger={["click"]}
          overlay={
            <Menu>
              {_.reject(
                profiles,
                (p) =>
                  !_.includes(
                    _.map(_.get(p, "memberships"), "account_id"),
                    selectedAccount?.account_id
                  )
              ).map((profile) => (
                <Menu.Item
                  icon={
                    profile.type === "amazon" ? (
                      <AmazonIcon
                        className="inline mr-3"
                        style={{ fill: "#FF9900" }}
                      />
                    ) : (
                      <InstacartIcon
                        className="inline mr-3"
                        style={{ fill: "#43B02A" }}
                      />
                    )
                  }
                  key={profile.profile_id}
                  onClick={() => setSelectedProfile(profile)}
                >
                  {profile.account_brand_name}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <a
            href="#/"
            onClick={(e) => e.preventDefault()}
            className="font-bold text-black mb-0 ml-4 flex items-center"
          >
            {selectedProfile
              ? selectedProfile.account_brand_name
              : "Select a profile"}
            <CaretDownFilled className="ml-2" />
          </a>
        </Dropdown>
      </div>

      <div className="flex h-full items-center">
        {isDashboardPage ? (
          <DoubleRangeDatePicker
            value={{
              comparedTo: [
                Dayjs(comparedToRange.start),
                Dayjs(comparedToRange.end),
                "daily",
              ],
              current: [Dayjs(range.start), Dayjs(range.end)],
            }}
            onChange={({ current, comparedTo }) =>
              setRanges(current, comparedTo)
            }
          />
        ) : (
          <DatePicker.RangePicker
            size="large"
            format="MM/DD/YYYY"
            showTime={{ format: "MM/DD/YYYY" }}
            placeholder={["Start Time", "End Time"]}
            value={[Dayjs(range.start), Dayjs(range.end)]}
            onCalendarChange={(current) => {
              if (!current) {
                return;
              }

              const start = current[0];
              const end = current[1];

              if (!start || !end) {
                return;
              }

              setRanges([start, end]);
            }}
          />
        )}

        <div className="border-l border-gray-base h-8 flex items-center px-4 cursor-pointer">
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {accounts.map((account) => (
                  <Menu.Item
                    key={account.account_id}
                    onClick={() => setSelectedAccount(account)}
                  >
                    {account.account_name}
                  </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item key="logout" onClick={setLogout}>
                  Logout
                </Menu.Item>
              </Menu>
            }
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="font-bold text-black mb-0 ml-4 flex items-center"
            >
              {selectedAccount
                ? selectedAccount.account_name
                : "Select an account"}
              <CaretDownFilled className="ml-2" />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
