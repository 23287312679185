import Dayjs from "dayjs";
import { refreshAccessToken } from "helpers/api-calls";

const refreshTokenKey = "refresh_token";
const accessTokenKey = "access_token";

const createCookie = (name: string, value: string, expires?: Date) => {
  let expire = "";

  if (expires) {
    expire = expires.toUTCString();
  } else {
    expire = "-1";
  }

  document.cookie = `${name}=${value}; expires=${expire}; path=/`;
};

const readCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

const eraseCookie = (name: string) => {
  createCookie(name, "");
};

const saveTokens = (
  accessToken: string,
  refreshToken: string,
  expires: Date
) => {
  createCookie(accessTokenKey, accessToken, expires);
  createCookie(refreshTokenKey, refreshToken, Dayjs().add(30, "days").toDate());
};

const getToken = async () => {
  const accessToken = readCookie(accessTokenKey);
  const refreshToken = readCookie(refreshTokenKey);

  if (accessToken) {
    return accessToken;
  }

  if (refreshToken) {
    const res = await refreshAccessToken({
      refresh_token: refreshToken,
    });

    if (!res.ok) {
      deleteToken();

      return null;
    }

    saveTokens(
      res.json.access_token,
      res.json.refresh_token,
      new Date(res.json.expires_at)
    );

    return res.json.access_token;
  }

  return null;
};

const deleteToken = () => {
  eraseCookie(accessTokenKey);
  eraseCookie(refreshTokenKey);
};

const checkForToken = () => !!readCookie(accessTokenKey);

export { saveTokens, deleteToken, checkForToken, getToken };
