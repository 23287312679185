import * as React from "react";
import { withRouter } from "react-router";
import { aboutMe } from "helpers/api-calls";
import { deleteToken, saveTokens } from "helpers/tokens";
import { Components } from "types/api";

const { useState, useEffect } = React;

interface AuthContextType {
  authenticated: boolean;
  isAdmin: boolean;
  id: string | null;
  name: string | null;
  username: string | null;
  checkingToken: boolean;
  memberships: Components.Schemas.SparseUserMembership[];
}

const defaultAuthContext = {
  authenticated: false,
  loggingIn: false,
  isStaff: false,
  isAdmin: false,
  firstName: null,
  lastName: null,
  id: null,
  name: null,
  username: null,
  checkingToken: true,
  memberships: [],
} as AuthContextType;

export type AuthContextInterface = [
  AuthContextType,
  // The loginUser function, takes in the user object and sets to context
  (accessToken: string, refreshToken: string, expires: Date) => void,
  // the logout function, which removes the user's token from cookie and unsets it from ctx
  () => void,
  // the fetch and set user function, which fetches the user using token, fetches profiles
  // and sets these values to ctx
  () => Promise<void>
];

export const AuthContext = React.createContext<AuthContextInterface>([
  defaultAuthContext,
  () => {},
  () => {},
  () => Promise.resolve(),
]);

const { Provider: AuthContextProvider } = AuthContext;

/**
 *
 * This wrapper sets 4 values to context:  auth, loginUser, setLogout, and fetchAndSetUser
 * auth contains the logged in user's information, login state, loading state.
 * loginUser is a function used to set a user as logged in.
 * setLogout logs out the user, and fetchAndSetUser is
 * called to fetch the auth user from the api
 * and set it to ctx. It also fetches the
 * profiles immediately after.
 */
export const AuthContextProviderWrapper = withRouter(
  ({ children, history }) => {
    const [auth, setAuth] = useState<AuthContextInterface[0]>(
      defaultAuthContext
    );

    const setLogout = () => {
      deleteToken();

      setAuth({
        ...auth,
        isAdmin: false,
        authenticated: false,
        checkingToken: false,
      });

      history.push("/login");
    };

    const fetchAndSetUser = async () => {
      const me = await aboutMe();

      if (!me.ok) {
        setLogout();

        return;
      }

      setAuth({
        id: me.json.id,
        name: me.json.name,
        username: me.json.username,
        memberships: me.json.memberships,
        isAdmin: me.json.is_admin,
        checkingToken: false,
        authenticated: true,
      });
    };

    useEffect(() => {
      fetchAndSetUser();
      // eslint-disable-next-line
    }, []);

    const loginUser = (
      accessToken: string,
      refreshToken: string,
      expires: Date
    ) => {
      saveTokens(accessToken, refreshToken, expires);
      fetchAndSetUser();
      history.push("/");
    };

    return (
      <AuthContextProvider
        value={[auth, loginUser, setLogout, fetchAndSetUser]}
      >
        {auth.checkingToken ? null : children}
      </AuthContextProvider>
    );
  }
);
