import apicalls from "helpers/requests";
import { Paths } from "types/api";

/**
 * amazon ad group
 */

/**
 * List available Amazon ad groups
 */
export const listAmazonAdGroups = (
  params: Paths.ListAmazonAdGroups.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonAdGroups.Responses.$200>(
    "ams/ad_groups",
    params
  );

// POST /ams/ad_groups

/**
 * Bulk archive Amazon ad groups
 */
export const bulkArchiveAmazonAdGroups = (
  params: Paths.BulkArchiveAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.BulkArchiveAmazonAdGroups.Responses.$202>(
    "ams/ad_groups/bulk_archive",
    params
  );

/**
 * Bulk enable Amazon ad groups
 */
export const bulkEnableAmazonAdGroups = (
  params: Paths.BulkEnableAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonAdGroups.Responses.$202>(
    "ams/ad_groups/bulk_enable",
    params
  );

/**
 * Bulk pause Amazon ad groups
 */
export const bulkPauseAmazonAdGroups = (
  params: Paths.BulkPauseAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonAdGroups.Responses.$202>(
    "ams/ad_groups/bulk_pause",
    params
  );

/**
 * Export Amazon AdGroups
 */
export const exportAmazonAdGroups = (
  params: Paths.ExportAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonAdGroups.Responses.$202>(
    "ams/ad_groups/export",
    params
  );

/**
 * Search available Amazon ad groups
 */
export const searchAmazonAdGroups = (
  params: Paths.SearchAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonAdGroups.Responses.$200>(
    "ams/ad_groups/search",
    params
  );

/**
 * Get search totals for Amazon ad groups
 */
export const searchTotalsForAmazonAdGroups = (
  params: Paths.SearchTotalsForAmazonAdGroups.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonAdGroups.Responses.$200>(
    "ams/ad_groups/totals",
    params
  );

// DELETE /ams/ad_groups/{ad_group_id}
// GET /ams/ad_groups/{ad_group_id}
// PUT /ams/ad_groups/{ad_group_id}

/**
 * amazon ad group collection
 */

/**
 * List Amazon ad group collections.
 */
export const listAmazonAdGroupCollections = (
  params: Paths.ListAmazonAdGroupCollections.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonAdGroupCollections.Responses.$200>(
    "ams/ad_group_collections",
    params
  );

/**
 * Create Amazon ad group collection
 */
export const createAmazonAdGroupCollection = (
  params: Paths.CreateAmazonAdGroupCollection.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonAdGroupCollection.Responses.$201>(
    "ams/ad_group_collections",
    params
  );

/**
 * Bulk add Amazon ad groups to Amazon ad group collections
 */
export const bulkAddAdGroupsToAmazonAdGroupCollections = (
  params: Paths.BulkAddAdGroupsToAmazonAdGroupCollections.RequestBody
) =>
  apicalls.post<Paths.BulkAddAdGroupsToAmazonAdGroupCollections.Responses.$202>(
    "ams/ad_group_collections/bulk_add_ad_groups",
    params
  );

/**
 * Bulk remove Amazon ad groups to Amazon ad group collections
 */
export const bulkRemoveAdGroupsFromAmazonAdGroupCollections = (
  params: Paths.BulkRemoveAdGroupsFromAmazonAdGroupCollections.RequestBody
) =>
  apicalls.post<Paths.BulkRemoveAdGroupsFromAmazonAdGroupCollections.Responses.$202>(
    "ams/ad_group_collections/bulk_remove_ad_groups",
    params
  );

/**
 * Delete Amazon ad group collection
 */
export const deleteAmazonAdGroupCollection = (
  params: Paths.DeleteAmazonAdGroupCollection.PathParameters
) => apicalls.delete(`ams/ad_group_collections/${params.collection_id}`);

// GET /ams/ad_group_collections/{collection_id}

/**
 * Update Amazon ad group collection
 */
export const updateAmazonAdGroupCollection = (
  params: Paths.UpdateAmazonAdGroupCollection.PathParameters &
    Paths.UpdateAmazonAdGroupCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateAmazonAdGroupCollection.Responses.$200>(
    `ams/ad_group_collections/${params.collection_id}`,
    params
  );

/**
 * List ad groups attached to the ad group collection
 */
export const listAmazonAdGroupsForAdGroupCollection = (
  params: Paths.ListAmazonAdGroupsForAdGroupCollection.PathParameters &
    Paths.ListAmazonAdGroupsForAdGroupCollection.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonAdGroupsForAdGroupCollection.Responses.$200>(
    `ams/ad_group_collections/${params.collection_id}/ad_groups`,
    params
  );

/**
 * amazon asin
 */

/**
 * Bulk enable Amazon ASINs
 */
export const bulkEnableAmazonAsins = (
  params: Paths.BulkEnableAmazonAsins.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonAsins.Responses.$202>(
    "ams/asins/bulk_enable",
    params
  );

/**
 * Bulk pause Amazon ASINs
 */
export const bulkPauseAmazonAsins = (
  params: Paths.BulkPauseAmazonAsins.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonAsins.Responses.$202>(
    "ams/asins/bulk_pause",
    params
  );

/**
 * Export Amazon ASINs
 */
export const exportAmazonAsins = (
  params: Paths.ExportAmazonAsins.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonAsins.Responses.$202>(
    "ams/asins/export",
    params
  );

/**
 * Search Amazon asins
 */
export const searchAmazonAsins = (
  params: Paths.SearchAmazonAsins.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonAsins.Responses.$200>(
    "ams/asins/search",
    params
  );

/**
 * Get search totals for Amazon asin
 */
export const searchTotalsforAmazonAsins = (
  params: Paths.SearchTotalsForAmazonAsins.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonAsins.Responses.$200>(
    "ams/asins/totals",
    params
  );

/**
 * amazon asin collection
 */

/**
 * List Amazon ASIN collections
 */
export const listAmazonAsinCollections = (
  params: Paths.ListAmazonAsinCollections.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonAsinCollections.Responses.$200>(
    "ams/asin_collections",
    params
  );

/**
 * Create Amazon ASIN collection
 */
export const createAmazonAsinCollection = (
  params: Paths.CreateAmazonAsinCollection.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonAsinCollection.Responses.$201>(
    "ams/asin_collections",
    params
  );

/**
 * Bulk enable an Amazon asin collection
 */
export const bulkEnableAmazonAsinCollections = (
  params: Paths.BulkEnableAmazonAsinCollections.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonAsinCollections.Responses.$202>(
    "ams/asins_collections/bulk_enable",
    params
  );

/**
 * Bulk pause an Amazon asin collection
 */
export const bulkPauseAmazonAsinCollections = (
  params: Paths.BulkPauseAmazonAsinCollections.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonAsinCollections.Responses.$202>(
    "ams/asins_collections/bulk_pause",
    params
  );

/**
 * Delete Amazon ASIN collection
 */
export const deleteAmazonAsinCollection = (
  params: Paths.DeleteAmazonAsinCollection.PathParameters
) => apicalls.delete(`ams/asin_collections/${params.collection_id}`);

// GET /ams/asin_collections/{collection_id}

/**
 * Update Amazon ASIN collection
 */
export const updateAmazonAsinCollection = (
  params: Paths.UpdateAmazonAsinCollection.PathParameters &
    Paths.UpdateAmazonAsinCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateAmazonAsinCollection.Responses.$200>(
    `ams/asin_collections/${params.collection_id}`,
    params
  );

// POST /ams/asin_collections/{collection_id}/add_product

/**
 * Add Amazon products to an ASIN collection
 */
export const addAmazonProductsToAsinCollection = (
  params: Paths.AddAmazonProductsToAsinCollection.PathParameters &
    Paths.AddAmazonProductsToAsinCollection.RequestBody
) =>
  apicalls.post<Paths.AddAmazonProductsToAsinCollection.Responses.$200>(
    `ams/asin_collections/${params.collection_id}/add_products`,
    {
      asins: params.asins,
    }
  );

/**
 * List Amazon products in an ASIN collection
 */
export const listAmazonProductsInAsinCollection = (
  params: Paths.ListAmazonProductsInAsinCollection.PathParameters
) =>
  apicalls.get<Paths.ListAmazonProductsInAsinCollection.Responses.$200>(
    `ams/asin_collections/${params.collection_id}/products`
  );

// POST /ams/asin_collections/{collection_id}/remove_product

/**
 * Remove Amazon products from an ASIN collection
 */
export const removeAmazonProductsFromAsinCollection = (
  params: Paths.RemoveAmazonProductsFromAsinCollection.PathParameters &
    Paths.RemoveAmazonProductsFromAsinCollection.RequestBody
) =>
  apicalls.post<Paths.RemoveAmazonProductsFromAsinCollection.Responses.$200>(
    `ams/asin_collections/${params.collection_id}/remove_products`,
    {
      asins: params.asins,
    }
  );

/**
 * amazon bid adjustment
 */

/**
 * Create Amazon bid adjustment
 */
export const createAmazonBidAdjustment = (
  params: Paths.CreateAmazonBidAdjustment.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonBidAdjustment.Responses.$200>(
    "ams/bid_adjustments",
    params
  );

// DELETE /ams/bid_adjustments
// GET /ams/bid_adjustments/{id}
// PUT /ams/bid_adjustments/{id}

/**
 * amazon bid adjustment setting
 */

/**
 * List Amazon bid adjustment settings
 */
export const listAmazonBidAdjustmentSettings = (
  params: Paths.ListAmazonBidAdjustmentSettings.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonBidAdjustmentSettings.Responses.$200>(
    "ams/bid_adjustment_settings",
    params
  );

/**
 * Create Amazon bid adjustment setting
 */
export const createAmazonBidAdjustmentSetting = (
  params: Paths.CreateAmazonBidAdjustmentSetting.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonBidAdjustmentSetting.Responses.$200>(
    "ams/bid_adjustment_settings",
    params
  );

/**
 * Delete Amazon bid adjustment setting
 */
export const deleteAmazonBidAdjustment = (
  params: Paths.DeleteAmazonBidAdjustment.PathParameters
) => apicalls.delete(`ams/bid_adjustment_settings/${params.id}`);

/**
 * Get Amazon bid adjustment setting
 */
export const getAmazonBidAdjustmentSetting = (
  params: Paths.GetAmazonBidAdjustmentSetting.PathParameters
) =>
  apicalls.get<Paths.GetAmazonBidAdjustmentSetting.Responses.$200>(
    `ams/bid_adjustment_settings/${params.id}`
  );

/**
 * Update Amazon bid adjustment setting
 */
export const updateAmazonBidAdjustmentSetting = (
  params: Paths.UpdateAmazonBidAdjustmentSetting.PathParameters &
    Paths.UpdateAmazonBidAdjustmentSetting.RequestBody
) =>
  apicalls.put<Paths.UpdateAmazonBidAdjustmentSetting.Responses.$200>(
    `ams/bid_adjustment_settings/${params.id}`,
    params
  );

/**
 * amazon campaign
 */

// GET /ams/campaigns

/**
 * Archive multiple campaigns
 */
export const bulkArchiveAmazonCampaigns = (
  params: Paths.BulkArchiveAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.BulkArchiveAmazonCampaigns.Responses.$202>(
    "ams/campaigns/bulk_archive",
    params
  );

/**
 * Enable multiple campaigns
 */
export const bulkEnableAmazonCampaigns = (
  params: Paths.BulkEnableAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonCampaigns.Responses.$202>(
    "ams/campaigns/bulk_enable",
    params
  );

/**
 * Pause multiple campaigns
 */
export const bulkPauseAmazonCampaigns = (
  params: Paths.BulkPauseAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonCampaigns.Responses.$202>(
    "ams/campaigns/bulk_pause",
    params
  );

/**
 * Export Amazon Campaigns
 */
export const exportAmazonCampaigns = (
  params: Paths.ExportAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonCampaigns.Responses.$202>(
    "ams/campaigns/export",
    params
  );

// GET /ams/campaigns/out_of_budget

/**
 * Get a list of Amazon campaigns out of budget
 */
export const getAmazonCampaignsOutOfBudget = (
  params: Paths.GetAmazonCampaignsOutOfBudget.QueryParameters
) =>
  apicalls.get<Paths.GetAmazonCampaignsOutOfBudget.Responses.$200>(
    `ams/campaigns/out_of_budget`,
    params
  );

export const searchAmazonCampaigns = (
  params: Paths.SearchAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonCampaigns.Responses.$200>(
    `ams/campaigns/search`,
    params
  );

/**
 * Get search totals for Amazon campaigns
 */
export const searchTotalsForAmazonCampaigns = (
  params: Paths.SearchTotalsForAmazonCampaigns.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonCampaigns.Responses.$200>(
    "ams/campaigns/totals",
    params
  );

// GET /ams/campaigns/{campaign_id}

/**
 * List all Amazon ad groups attached to the Amazon campaign
 */
export const getAmazonCampaignAdGroups = (
  params: Paths.GetAmazonCampaignAdGroups.PathParameters &
    Paths.GetAmazonCampaignAdGroups.QueryParameters
) =>
  apicalls.get<Paths.GetAmazonCampaignAdGroups.Responses.$200>(
    `ams/campaigns/${params.campaign_id}/ad_groups`,
    params
  );

/**
 * amazon campaign collections
 */

/**
 * List campaign collections
 */
export const listAmazonCampaignCollections = (
  params: Paths.ListAmazonCampaignCollections.QueryParameters = {}
) =>
  apicalls.get<Paths.ListAmazonCampaignCollections.Responses.$200>(
    "ams/campaign_collections",
    params
  );

/**
 * Create campaign collection
 */
export const createAmazonCampaignCollection = (
  params: Paths.CreateAmazonCampaignCollection.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonCampaignCollection.Responses.$201>(
    "ams/campaign_collections",
    params
  );

/**
 * Bulk add Amazon campaigns to Amazon campaign collections
 */
export const bulkAddAmazonCampaignsToCampaignCollections = (
  params: Paths.BulkAddAmazonCampaignsToCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkAddAmazonCampaignsToCampaignCollections.Responses.$202>(
    "ams/campaign_collections/bulk_add_campaigns",
    params
  );

/**
 * Bulk enable a Amazon campaign collections
 */
export const bulkEnableAmazonCampaignCollections = (
  params: Paths.BulkEnableAmazonCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonCampaignCollections.Responses.$202>(
    "ams/campaign_collections/bulk_enable",
    params
  );

/**
 * Bulk pause a Amazon campaign collections
 */
export const bulkPauseAmazonCampaignCollections = (
  params: Paths.BulkPauseAmazonCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonCampaignCollections.Responses.$202>(
    "ams/campaign_collections/bulk_pause",
    params
  );

/**
 * Bulk remove Amazon campaigns from Amazon campaign collections
 */
export const bulkRemoveAmazonCampaignsFromCampaignCollections = (
  params: Paths.BulkRemoveAmazonCampaignsFromCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkRemoveAmazonCampaignsFromCampaignCollections.Responses.$202>(
    "ams/campaign_collections/bulk_remove_campaigns",
    params
  );

/**
 * Delete Amazon campaign collection
 */
export const deleteAmazonCampaignCollection = (
  params: Paths.DeleteAmazonCampaignCollection.PathParameters
) => apicalls.delete(`ams/campaign_collections/${params.collection_id}`);

// GET /ams/campaign_collections/{collection_id}

/**
 * Update Amazon campaign collection
 */
export const updateAmazonCampaignCollection = (
  params: Paths.UpdateAmazonCampaignCollection.PathParameters &
    Paths.UpdateAmazonCampaignCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateAmazonCampaignCollection.Responses.$200>(
    `ams/campaign_collections/${params.collection_id}`,
    params
  );

/**
 * Get Amazon campaign collections in the collection
 */
export const getAmazonCampaignCollectionCampaigns = (
  params: Paths.GetAmazonCampaignCollectionCampaigns.PathParameters
) =>
  apicalls.get<Paths.GetAmazonCampaignCollectionCampaigns.Responses.$200>(
    `ams/campaign_collections/${params.collection_id}/campaigns`
  );

/**
 * amazon dashboard
 */

/**
 * Get Amazon cumulative spend
 */
export const getAmazonSpendCumulativeSpend = (
  params: Paths.GetAmazonSpendCumulativeSpend.QueryParameters
) =>
  apicalls.get<Paths.GetAmazonSpendCumulativeSpend.Responses.$200>(
    `dashboard/amazon/spend/cumulative`,
    params
  );

/**
 * Get Amazon spend pacing
 */
export const getAmazonSpendPacing = (
  params: Paths.GetAmazonSpendPacing.RequestBody
) =>
  apicalls.post<Paths.GetAmazonSpendPacing.Responses.$200>(
    `dashboard/amazon/spend/pacing`,
    params
  );

/**
 * Get Amazon performance by ad type statistics
 */
export const getAmazonPerformanceByAdTypes = (
  params: Paths.GetAmazonPerformanceByAdTypes.RequestBody
) =>
  apicalls.post<Paths.GetAmazonPerformanceByAdTypes.Responses.$200>(
    `dashboard/amazon/statistics/ad_types`,
    params
  );

/**
 * Get Amazon performance by brand type statistics
 */
export const getAmazonPerformanceByBrandTypes = (
  params: Paths.GetAmazonPerformanceByBrandTypes.RequestBody
) =>
  apicalls.post<Paths.GetAmazonPerformanceByBrandTypes.Responses.$200>(
    `dashboard/amazon/statistics/brand_types`,
    params
  );

/**
 * Get Amazon segmented performance statistics
 */
export const getAmazonSegmentedPerformanceStatistics = (
  params: Paths.GetAmazonSegmentedPerformanceStatistics.RequestBody
) =>
  apicalls.post<Paths.GetAmazonSegmentedPerformanceStatistics.Responses.$200>(
    `dashboard/amazon/statistics/segments`,
    params
  );

/**
 * Get Amazon performance totals
 */
export const getAmazonPerformanceTotals = (
  params: Paths.GetAmazonPerformanceTotals.RequestBody
) =>
  apicalls.post<Paths.GetAmazonPerformanceTotals.Responses.$200>(
    "dashboard/amazon/statistics/totals",
    params
  );

/**
 * amazon keyword
 */

/**
 * Create Amazon keyword
 */
export const createAmazonKeyword = (
  params: Paths.CreateAmazonKeyword.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonKeyword.Responses.$200>(
    "ams/keywords",
    params
  );

/**
 * Adjust Amazon keywords
 */
export const adjustAmazonKeywordBids = (
  params: Paths.AdjustAmazonKeywordBids.RequestBody
) =>
  apicalls.post<Paths.AdjustAmazonKeywordBids.Responses.$200>(
    "ams/keywords/adjust_bids",
    params
  );

/**
 * Adjust Amazon keywords by ACoS
 */
export const adjustAmazonKeywordBidsByAcos = (
  params: Paths.AdjustAmazonKeywordBidsByAcos.RequestBody
) =>
  apicalls.post<Paths.AdjustAmazonKeywordBidsByAcos.Responses.$200>(
    "ams/keywords/adjust_bids_by_acos",
    params
  );

/**
 * Bulk archive Amazon keywords
 */
export const bulkArchiveAmazonKeywords = (
  params: Paths.BulkArchiveAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.BulkArchiveAmazonKeywords.Responses.$202>(
    "ams/keywords/bulk_archive",
    params
  );

/**
 * Bulk enable Amazon keywords
 */
export const bulkEnableAmazonKeywords = (
  params: Paths.BulkEnableAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonKeywords.Responses.$202>(
    "ams/keywords/bulk_enable",
    params
  );

/**
 * Bulk pause Amazon keywords
 */
export const bulkPauseAmazonKeywords = (
  params: Paths.BulkPauseAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonKeywords.Responses.$202>(
    "ams/keywords/bulk_pause",
    params
  );

// GET /ams/keywords/duplicate_competing

/**
 * Export Amazon Keywords
 */
export const exportAmazonKeywords = (
  params: Paths.ExportAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonKeywords.Responses.$202>(
    "ams/keywords/export",
    params
  );

/**
 * Search Amazon Keywords
 */
export const searchAmazonKeywords = (
  params: Paths.SearchAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonKeywords.Responses.$200>(
    "ams/keywords/search",
    params
  );

/**
 * Get Amazon Keyword performance statistics
 */
export const getAmazonKeywordPerformanceStatistics = (
  params: Paths.GetAmazonKeywordPerformanceStatistics.RequestBody
) =>
  apicalls.post<Paths.GetAmazonKeywordPerformanceStatistics.Responses.$200>(
    `ams/keywords/statistics`,
    params
  );

/**
 * Search Amazon Keywords
 */
export const searchTotalsForAmazonKeywords = (
  params: Paths.SearchTotalsForAmazonKeywords.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonKeywords.Responses.$200>(
    "ams/keywords/totals",
    params
  );

/**
 * amazon negative keyword
 */

/**
 * Create an Amazon negative keyword
 */
export const createAmazonNegativeKeyword = (
  params: Paths.CreateAmazonNegativeKeyword.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonNegativeKeyword.Responses.$202>(
    "ams/negative_keywords",
    params
  );

/**
 * Delete an Amazon negative keyword
 */
export const deleteAmazonNegativeKeyword = (
  params: Paths.DeleteAmazonNegativeKeyword.PathParameters
) => apicalls.delete(`ams/negative_keywords/${params.id}`);

/**
 * Get an Amazon negative keyword
 */
export const getAmazonNegativeKeyword = (
  params: Paths.GetAmazonNegativeKeyword.PathParameters
) =>
  apicalls.get<Paths.GetAmazonNegativeKeyword.Responses.$200>(
    `ams/negative_keywords/${params.id}`
  );

/**
 * amazon negative keyword list
 */

/**
 * List Amazon negative keyword lists
 */
export const listAmazonNegativeKeywordLists = (
  params: Paths.ListAmazonNegativeKeywordLists.QueryParameters
) =>
  apicalls.get<Paths.ListAmazonNegativeKeywordLists.Responses.$200>(
    "ams/negative_keyword_lists",
    params
  );

/**
 * Create an Amazon negative keyword list
 */
export const createAmazonNegativeKeywordList = (
  params: Paths.CreateAmazonNegativeKeywordList.RequestBody
) =>
  apicalls.post<Paths.CreateAmazonNegativeKeywordList.Responses.$200>(
    "ams/negative_keyword_lists",
    params
  );

/**
 * Delete an Amazon negative keyword list
 */
export const deleteAmazonNegativeKeywordList = (
  params: Paths.DeleteAmazonNegativeKeywordList.PathParameters
) => apicalls.delete(`ams/negative_keyword_lists/${params.list_id}`);

/**
 * Get Amazon negative keyword list
 */
export const getAmazonNegativeKeywordList = (
  params: Paths.GetAmazonNegativeKeywordList.PathParameters
) =>
  apicalls.get<Paths.GetAmazonNegativeKeywordList.Responses.$200>(
    `ams/negative_keyword_lists/${params.list_id}`
  );

/**
 * Update an Amazon negative keyword list
 */
export const updateAmazonNegativeKeywordList = (
  params: Paths.UpdateAmazonNegativeKeywordList.PathParameters &
    Paths.UpdateAmazonNegativeKeywordList.RequestBody
) =>
  apicalls.put<Paths.UpdateAmazonNegativeKeywordList.Responses.$200>(
    `ams/negative_keyword_lists/${params.list_id}`,
    params
  );

/**
 * Add Amazon campaign to a negative keyword list
 */
export const addAmazonCampaignToNegativeKeywordList = (
  params: Paths.AddAmazonCampaignToNegativeKeywordList.PathParameters &
    Paths.AddAmazonCampaignToNegativeKeywordList.RequestBody
) =>
  apicalls.post<Paths.AddAmazonCampaignToNegativeKeywordList.Responses.$202>(
    `ams/negative_keyword_lists/${params.list_id}/add_campaigns`,
    params
  );

/**
 * Get Amazon negative keyword list changes
 */
export const getAmazonNegativeKeywordListCampaigns = (
  params: Paths.GetAmazonNegativeKeywordListCampaigns.PathParameters
) =>
  apicalls.get<Paths.GetAmazonNegativeKeywordListCampaigns.Responses.$200>(
    `ams/negative_keyword_lists/${params.list_id}/campaigns`
  );

/**
 * Get Amazon negative keyword list changes
 */
export const getAmazonNegativeKeywordListChanges = (
  params: Paths.GetAmazonNegativeKeywordListChanges.PathParameters
) =>
  apicalls.get<Paths.GetAmazonNegativeKeywordListChanges.Responses.$200>(
    `ams/negative_keyword_lists/${params.list_id}/changes`
  );

/**
 * Remove Amazon campaign from a negative keyword list
 */
export const removeAmazonCampaignFromNegativeKeywordList = (
  params: Paths.RemoveAmazonCampaignFromNegativeKeywordList.PathParameters &
    Paths.RemoveAmazonCampaignFromNegativeKeywordList.RequestBody
) =>
  apicalls.post<Paths.RemoveAmazonCampaignFromNegativeKeywordList.Responses.$202>(
    `ams/negative_keyword_lists/${params.list_id}/remove_campaigns`,
    params
  );

/**
 * amazon product ad
 */

/**
 * Bulk archive Amazon product ads
 */
export const bulkArchiveAmazonProductAds = (
  params: Paths.BulkArchiveAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.BulkArchiveAmazonProductAds.Responses.$202>(
    "ams/product_ads/bulk_archive",
    params
  );

/**
 * Bulk enable Amazon product ads
 */
export const bulkEnableAmazonProductAds = (
  params: Paths.BulkEnableAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAmazonProductAds.Responses.$202>(
    "ams/product_ads/bulk_enable",
    params
  );

/**
 * Bulk pause Amazon product ads
 */
export const bulkPauseAmazonProductAds = (
  params: Paths.BulkPauseAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAmazonProductAds.Responses.$202>(
    "ams/product_ads/bulk_pause",
    params
  );

/**
 * Export Amazon ProductAds.
 */
export const exportAmazonProductAds = (
  params: Paths.ExportAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonProductAds.Responses.$202>(
    "ams/product_ads/export",
    params
  );

/**
 * Search Amazon product ads.
 */
export const searchAmazonProductAds = (
  params: Paths.SearchAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonProductAds.Responses.$200>(
    "ams/product_ads/search",
    params
  );

/**
 * Get search totals for Amazon product ads
 */
export const searchTotalsForAmazonProductAds = (
  params: Paths.SearchTotalsForAmazonProductAds.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonProductAds.Responses.$200>(
    "ams/product_ads/totals",
    params
  );

/**
 * amazon profile
 */

/**
 * Get Amazon profiles
 */
export const listAmazonProfiles = () =>
  apicalls.get<Paths.ListAmazonProfiles.Responses.$200>("ams/profiles");

// GET /ams/profiles/{profile_id}

/**
 * Get incorrect keyword broad matches
 */
export const getAmazonIncorrectKeywordBroadMatches = (
  params: Paths.GetAmazonIncorrectKeywordBroadMatches.PathParameters &
    Paths.GetAmazonIncorrectKeywordBroadMatches.QueryParameters
) =>
  apicalls.get<Paths.GetAmazonIncorrectKeywordBroadMatches.Responses.$200>(
    `ams/profiles/${params.profile_id}/incorrect_broad_matches`,
    params
  );

/**
 * Get incorrect keyword exact matches
 */
export const getAmazonIncorrectKeywordExactMatches = (
  params: Paths.GetAmazonIncorrectKeywordExactMatches.PathParameters &
    Paths.GetAmazonIncorrectKeywordExactMatches.QueryParameters
) =>
  apicalls.get<Paths.GetAmazonIncorrectKeywordExactMatches.Responses.$200>(
    `ams/profiles/${params.profile_id}/incorrect_exact_matches`,
    params
  );

/**
 * Get Amazon non asin sales
 */
export const getAmazonNonAsinSales = (
  params: Paths.GetAmazonNonAsinSales.PathParameters &
    Paths.GetAmazonNonAsinSales.RequestBody
) =>
  apicalls.post<Paths.GetAmazonNonAsinSales.Responses.$200>(
    `ams/profiles/${params.profile_id}/non_asin_sales`,
    params
  );

/**
 * amazon search term
 */

/**
 * Export Amazon search term reports.
 */
export const exportAmazonSearchTerms = (
  params: Paths.ExportAmazonSearchTerms.RequestBody
) =>
  apicalls.post<Paths.ExportAmazonSearchTerms.Responses.$202>(
    "ams/search_term_reports/export",
    params
  );

/**
 * Search Amazon search term reports.
 */
export const searchAmazonSearchTermReports = (
  params: Paths.SearchAmazonSearchTermReports.RequestBody
) =>
  apicalls.post<Paths.SearchAmazonSearchTermReports.Responses.$200>(
    "ams/search_term_reports/search",
    params
  );

/**
 * Get totals Amazon search term reports.
 */
export const searchTotalsForAmazonSearchTerms = (
  params: Paths.SearchTotalsForAmazonSearchTerms.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForAmazonSearchTerms.Responses.$200>(
    "ams/search_term_reports/totals",
    params
  );

/**
 * auth
 */

/**
 * Creates an access token based on user credentials
 */
export const createAccessToken = (
  params: Paths.CreateAccessToken.RequestBody
) =>
  apicalls.post<Paths.CreateAccessToken.Responses.$200>(
    "auth/token/create",
    params
  );

/**
 * Destroy the current access token
 */
export const destroyAccessToken = () =>
  apicalls.post<Paths.DestroyAccessToken.Responses.$204>(
    "auth/token/refresh",
    {}
  );

/**
 * Refresh an access token
 */
export const refreshAccessToken = (
  params: Paths.RefreshAccessToken.RequestBody
) =>
  apicalls.post<Paths.RefreshAccessToken.Responses.$200>(
    "auth/token/refresh",
    params
  );

/**
 * Get information on the access token
 */
export const aboutMe = () =>
  apicalls.get<Paths.AboutMe.Responses.$200>("auth/users/me");

/**
 * autobid
 */

// GET /autobid/rule_sets

/**
 * Create an autobid rule set
 */
export const createAutobidRuleSet = (
  params: Paths.CreateAutobidRuleSet.RequestBody
) =>
  apicalls.post<Paths.CreateAutobidRuleSet.Responses.$201>(
    `autobid/rule_sets`,
    params
  );

/**
 * Bulk enable autobid rule sets
 */
export const bulkEnableAutobidRuleSets = (
  params: Paths.BulkEnableAutobidRuleSets.RequestBody
) =>
  apicalls.post<Paths.BulkEnableAutobidRuleSets.Responses.$202>(
    `autobid/rule_sets/bulk_enable`,
    params
  );

/**
 * Bulk pause autobid rule sets
 */
export const bulkPauseAutobidRuleSets = (
  params: Paths.BulkPauseAutobidRuleSets.RequestBody
) =>
  apicalls.post<Paths.BulkPauseAutobidRuleSets.Responses.$202>(
    `autobid/rule_sets/bulk_pause`,
    params
  );

/**
 * Search autobid rule sets
 */
export const listAutobidRuleSets = (
  params: Paths.ListAutobidRuleSets.QueryParameters
) =>
  apicalls.post<Paths.ListAutobidRuleSets.Responses.$200>(
    `autobid/rule_sets/search`,
    params
  );

/**
 * Delete autobid rule set
 */
export const deleteAutobidRuleset = (
  params: Paths.DeleteAutobidRuleSet.PathParameters
) => apicalls.delete(`autobid/rule_sets/${params.rule_set_id}`);

/**
 * Get autobid rule set
 */
export const getAutobidRuleset = (
  params: Paths.GetAutobidRuleSet.PathParameters
) =>
  apicalls.get<Paths.GetAutobidRuleSet.Responses.$200>(
    `autobid/rule_sets/${params.rule_set_id}`
  );

/**
 * Update autobid rule set
 */
export const updateAutobidRuleset = ({
  rule_set_id,
  ...rest
}: Paths.UpdateAutobidRuleSet.PathParameters &
  Paths.UpdateAutobidRuleSet.RequestBody) =>
  apicalls.put<Paths.UpdateAutobidRuleSet.Responses.$200>(
    `autobid/rule_sets/${rule_set_id}`,
    rest
  );

/**
 * Add campaign collections to autobid rule set
 */
export const addAmazonCampaignCollectionsToAutobidRuleset = (
  params: Paths.AddAmazonCampaignCollectionsToAutobidRuleset.PathParameters &
    Paths.AddAmazonCampaignCollectionsToAutobidRuleset.RequestBody
) =>
  apicalls.post<Paths.AddAmazonCampaignCollectionsToAutobidRuleset.Responses.$200>(
    `autobid/rule_sets/${params.rule_set_id}/add_campaign_collections`,
    params
  );

/**
 * List autobid rule set campaign collections
 */
export const listAutobidRuleSetCampaignCollections = (
  params: Paths.ListAutobidRuleSetCampaignCollections.PathParameters &
    Paths.ListAutobidRuleSetCampaignCollections.QueryParameters
) =>
  apicalls.get<Paths.ListAutobidRuleSetCampaignCollections.Responses.$200>(
    `autobid/rule_sets/${params.rule_set_id}/campaign_collections`
  );

/**
 * Remove campaign collections to autobid rule set
 */
export const removeAmazonCampaignCollectionsFromAutobidRuleset = (
  params: Paths.RemoveAmazonCampaignCollectionsFromAutobidRuleset.PathParameters &
    Paths.RemoveAmazonCampaignCollectionsFromAutobidRuleset.RequestBody
) =>
  apicalls.post<Paths.RemoveAmazonCampaignCollectionsFromAutobidRuleset.Responses.$200>(
    `autobid/rule_sets/${params.rule_set_id}/remove_campaign_collections`,
    params
  );

/**
 * automation
 */

/**
 * Create autobid filter
 */
export const createAutomationAutobidFilter = (
  params: Paths.CreateAutomationAutobidFilter.RequestBody
) =>
  apicalls.post<Paths.CreateAutomationAutobidFilter.Responses.$201>(
    "automation/autobid/filters",
    params
  );

/**
 * Delete an autobid filter
 */
export const deleteAutomationAutobidFilter = (
  params: Paths.DeleteAutomationAutobidFilter.PathParameters
) => apicalls.delete(`automation/autobid/filters/${params.filter_id}`);

/**
 * Update autobid filter
 */
export const updateAutomationAutobidFilter = (
  params: Paths.UpdateAutomationAutobidFilter.PathParameters &
    Paths.UpdateAutomationAutobidFilter.RequestBody
) =>
  apicalls.put<Paths.UpdateAutomationAutobidFilter.Responses.$200>(
    `automation/autobid/filters/${params.filter_id}`,
    params
  );

/**
 * Create autobid rule
 */
export const createAutomationAutobidRule = (
  params: Paths.CreateAutomationAutobidRule.RequestBody
) =>
  apicalls.post<Paths.CreateAutomationAutobidRule.Responses.$201>(
    "automation/autobid/rules",
    params
  );

/**
 * Delete an autobid rule
 */
export const deleteAutomationAutobidRule = (
  params: Paths.DeleteAutomationAutobidRule.PathParameters
) => apicalls.delete(`automation/autobid/rules/${params.rule_id}`);

/**
 * Update autobid rule
 */
export const updateAutomationAutobidRule = (
  params: Paths.UpdateAutomationAutobidRule.PathParameters &
    Paths.UpdateAutomationAutobidRule.RequestBody
) =>
  apicalls.put<Paths.UpdateAutomationAutobidRule.Responses.$200>(
    `automation/autobid/rules/${params.rule_id}`,
    params
  );

/**
 * Create autobid ruleset
 */
export const CreateAutomationAutobidRuleset = (
  params: Paths.CreateAutomationAutobidRuleset.RequestBody
) =>
  apicalls.post<Paths.CreateAutomationAutobidRuleset.Responses.$201>(
    "automation/autobid/rulesets",
    params
  );

/**
 * Search autobid rulesets
 */
export const searchAutobidRuleSets = (
  params: Paths.SearchAutomationAutobidRulesets.RequestBody
) =>
  apicalls.post<Paths.SearchAutomationAutobidRulesets.Responses.$200>(
    "automation/autobid/rulesets/search",
    params
  );

/**
 * Delete an autobid ruleset
 */
export const deleteAutomationAutobidRuleset = (
  params: Paths.DeleteAutomationAutobidRuleset.PathParameters
) => apicalls.delete(`automation/autobid/rulesets/${params.ruleset_id}`);

/**
 * Get autobid ruleset
 */
export const getAutobidRuleSet = (
  params: Paths.DeleteAutomationAutobidRuleset.PathParameters
) =>
  apicalls.get<Paths.GetAutobidRuleSet.Responses.$200>(
    `automation/autobid/rulesets/${params.ruleset_id}`
  );

/**
 * Update autobid ruleset
 */
export const updateAutomationAutobidRuleset = (
  params: Paths.UpdateAutomationAutobidRuleset.PathParameters &
    Paths.UpdateAutomationAutobidRuleset.RequestBody
) =>
  apicalls.put<Paths.GetAutobidRuleSet.Responses.$200>(
    `automation/autobid/rulesets/${params.ruleset_id}`,
    params
  );

/**
 * Add Amazon ad group collection to automation autobid ruleset
 */
export const addAmazonAdGroupCollectionToAutomationAutobidRuleset = (
  params: Paths.AddCollectionToAutomationAutobidRuleset.PathParameters &
    Paths.AddCollectionToAutomationAutobidRuleset.RequestBody
) =>
  apicalls.post<Paths.AddCollectionToAutomationAutobidRuleset.Responses.$201>(
    `automation/autobid/rulesets/${params.ruleset_id}/add_collection`,
    params
  );

/**
 * Disable autobid ruleset
 */
export const disableAutomationAutobidRuleset = (
  params: Paths.DisableAutomationAutobidRuleset.PathParameters
) =>
  apicalls.post<Paths.DisableAutomationAutobidRuleset.Responses.$200>(
    `automation/autobid/rulesets/${params.ruleset_id}/disable`,
    {}
  );

/**
 * Enable autobid ruleset
 */
export const enableAutomationAutobidRuleset = (
  params: Paths.EnableAutomationAutobidRuleset.PathParameters
) =>
  apicalls.post<Paths.EnableAutomationAutobidRuleset.Responses.$200>(
    `automation/autobid/rulesets/${params.ruleset_id}/enable`,
    {}
  );

/**
 * Remove Instacart campaign collection to automation autobid ruleset
 */
export const removeInstacartCampaignCollectionToAutomationAutobidRuleset = (
  params: Paths.RemoveCollectionFromAutomationAutobidRuleset.PathParameters &
    Paths.RemoveCollectionFromAutomationAutobidRuleset.RequestBody
) =>
  apicalls.post<Paths.RemoveCollectionFromAutomationAutobidRuleset.Responses.$201>(
    `automation/autobid/rulesets/${params.ruleset_id}/remove_collection`,
    params
  );

/**
 * day parting
 */

/**
 * List day parting settings
 */
export const listDayPartingSettings = (
  params: Paths.ListDayPartingSettings.QueryParameters
) =>
  apicalls.get<Paths.ListDayPartingSettings.Responses.$200>(
    `day_parting/settings`,
    params
  );

export const searchDaypartingSettings = (params: any) =>
  apicalls.post(`day_parting/settings/search`, params);

/**
 * Create day parting settings
 */
export const createDaypartingSetting = (
  params: Paths.CreateDayPartingSetting.RequestBody
) =>
  apicalls.post<Paths.CreateDayPartingSetting.Responses.$201>(
    `day_parting/settings`,
    params
  );

/**
 * Bulk enable day parting settings
 */
export const bulkEnableDaypartingSettings = (
  params: Paths.BulkEnableDayPartingSettings.RequestBody
) =>
  apicalls.post<Paths.BulkEnableDayPartingSettings.Responses.$202>(
    `day_parting/settings/bulk_enable`,
    params
  );

/**
 * Bulk pause day parting settings
 */
export const bulkPauseDaypartingSettings = (
  params: Paths.BulkPauseDayPartingSettings.RequestBody
) =>
  apicalls.post<Paths.BulkPauseDayPartingSettings.Responses.$202>(
    `day_parting/settings/bulk_pause`,
    params
  );

/**
 * Delete day parting setting
 */
export const deleteDaypartingSetting = (
  params: Paths.DeleteDayPartingSetting.PathParameters
) => apicalls.delete(`day_parting/settings/${params.setting_id}`);

// GET /day_parting/settings/{setting_id}

/**
 * Update day parting setting
 */
export const updateDayPartingSetting = (
  params: Paths.UpdateDayPartingSetting.PathParameters &
    Paths.UpdateDayPartingSetting.RequestBody
) =>
  apicalls.put<Paths.UpdateDayPartingSetting.Responses.$200>(
    `day_parting/settings/${params.setting_id}`,
    params
  );

/**
 * Add amazon campaign collections to day parting setting
 */
export const addAmazonCampaignCollectionsToDayPartingSetting = (
  params: Paths.AddAmazonCampaignCollectionsToDayPartingSetting.PathParameters &
    Paths.AddAmazonCampaignCollectionsToDayPartingSetting.RequestBody
) =>
  apicalls.post<Paths.AddAmazonCampaignCollectionsToDayPartingSetting.Responses.$200>(
    `day_parting/settings/${params.setting_id}/add_campaign_collections`,
    params
  );

/**
 * List day parting setting campaign collections
 */
export const listDayPartingAmazonCampaignCollections = (
  params: Paths.ListDayPartingAmazonCampaignCollections.PathParameters &
    Paths.ListDayPartingAmazonCampaignCollections.QueryParameters
) =>
  apicalls.get<Paths.ListDayPartingAmazonCampaignCollections.Responses.$200>(
    `day_parting/settings/${params.setting_id}/campaign_collections`
  );

/**
 * Remove amazon campaign collections from day parting setting
 */
export const removeAmazonCampaignCollectionsFromDayPartingSetting = (
  params: Paths.RemoveAmazonCampaignCollectionsFromDayPartingSetting.PathParameters &
    Paths.RemoveAmazonCampaignCollectionsFromDayPartingSetting.RequestBody
) =>
  apicalls.post<Paths.RemoveAmazonCampaignCollectionsFromDayPartingSetting.Responses.$200>(
    `day_parting/settings/${params.setting_id}/remove_campaign_collections`,
    params
  );

/**
 * exports
 */

/**
 * Deletes the generated export document
 */
export const deleteExportDocument = (
  params: Paths.DeleteExportDocument.PathParameters
) => apicalls.delete(`exports/documents/${params.document_id}`);

/**
 * Get the information on the export document
 */
export const getExportDocument = (
  params: Paths.GetExportDocument.PathParameters
) =>
  apicalls.get<Paths.GetExportDocument.Responses.$200>(
    `exports/documents/${params.document_id}`
  );

/**
 * identity
 */

// GET /identity/accounts
// POST /identity/accounts
// GET /identity/accounts/{account_id}
// PUT /identity/accounts/{account_id}
// GET /identity/memberships
// POST /identity/memberships
// DELETE /identity/memberships/{membership_id}
// GET /identity/memberships/{membership_id}
// PUT /identity/memberships/{membership_id}

/**
 * Request a reset password token
 */
export const forgotPassword = (params: Paths.ForgotPassword.RequestBody) =>
  apicalls.post<Paths.ForgotPassword.Responses.$200>(
    `identity/passwords/forgot`,
    params
  );

/**
 * Request reset password
 */
export const resetPassword = (params: Paths.ResetPassword.RequestBody) =>
  apicalls.post<Paths.ResetPassword.Responses.$200>(
    `identity/passwords/reset`,
    params
  );

// GET /identity/registration/{token}

/**
 * Complete user registration
 */
export const completeUserRegistration = (
  params: Paths.CompleteUserRegistration.PathParameters &
    Paths.CompleteUserRegistration.RequestBody
) =>
  apicalls.put<Paths.CompleteUserRegistration.Responses.$200>(
    `identity/registration/${params.token}`,
    params
  );

/**
 * instacart accounts
 */

/**
 * List Instacart accounts
 */
export const listInstacartAccounts = (
  params: Paths.ListInstacartAccounts.QueryParameters
) =>
  apicalls.get<Paths.ListInstacartAccounts.Responses.$200>(
    "instacart/accounts",
    params
  );

/**
 * Update Instacart account
 */
export const updateInstacartAccount = (
  params: Paths.UpdateInstacartAccount.PathParameters &
    Paths.UpdateInstacartAccount.RequestBody
) =>
  apicalls.put<Paths.UpdateInstacartAccount.Responses.$200>(
    `instacart/accounts/${params.id}`,
    params
  );

/**
 * instacart ad group collections
 */

/**
 * Create Instacart ad group collection
 */
export const createInstacartAdGroupCollection = (
  params: Paths.CreateInstacartAdGroupCollection.RequestBody
) =>
  apicalls.post<Paths.CreateInstacartCampaignCollection.Responses.$202>(
    "instacart/ad_group_collections",
    params
  );

/**
 * Bulk add Instacart ad groups to Intacart ad group collections
 */
export const bulkAddAdGroupsToInstacartAdGroupCollections = (
  params: Paths.BulkAddAdGroupsToInstacartAdGroupCollections.RequestBody
) =>
  apicalls.post<Paths.BulkAddAdGroupsToInstacartAdGroupCollections.Responses.$202>(
    "instacart/ad_group_collections/bulk_add",
    params
  );

/**
 * Bulk remove Instacart ad groups from Instacart ad group collections
 */
export const bulkRemoveAdGroupsFromInstacartAdGroupCollections = (
  params: Paths.BulkRemoveAdGroupsFromInstacartAdGroupCollections.RequestBody
) =>
  apicalls.post<Paths.BulkRemoveAdGroupsFromInstacartAdGroupCollections.Responses.$202>(
    "instacart/ad_group_collections/bulk_remove",
    params
  );

/**
 * Search Instacart ad group collections
 */
export const searchInstacartAdGroupCollections = (
  params: Paths.SearchInstacartAdGroupCollections.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartAdGroupCollections.Responses.$200>(
    "instacart/ad_group_collections/search",
    params
  );

/**
 * Destroy Instacart ad group collection
 */
export const destroyInstacartAdGroupCollection = (
  params: Paths.DestroyInstacartAdGroupCollection.PathParameters
) => apicalls.delete(`instacart/ad_group_collections/${params.collection_id}`);

/**
 * Update Instacart ad group collection
 */
export const updateInstacartAdGroupCollection = (
  params: Paths.UpdateInstacartAdGroupCollection.PathParameters &
    Paths.UpdateInstacartAdGroupCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateInstacartAdGroupCollection.Responses.$200>(
    `instacart/ad_group_collections/${params.collection_id}`,
    params
  );

/**
 * instacart ad groups
 */

/**
 * List Instacart ad groups
 */
export const listInstacartAdGroups = (
  params: Paths.ListInstacartAdGroups.QueryParameters
) =>
  apicalls.get<Paths.ListInstacartAdGroups.Responses.$200>(
    "instacart/ad_groups",
    params
  );

/**
 * Export Instacart Ad Groups
 */
export const exportInstacartAdGroups = (
  params: Paths.ExportInstacartAdGroups.RequestBody
) =>
  apicalls.post<Paths.ExportInstacartAdGroups.Responses.$202>(
    "instacart/ad_groups/export",
    params
  );

/**
 * Search available Instacart ad groups
 */
export const searchInstacartAdGroups = (
  params: Paths.SearchInstacartAdGroups.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartAdGroups.Responses.$200>(
    "instacart/ad_groups/search",
    params
  );

/**
 * Search available Instacart ad groups
 */
export const searchTotalsForInstacartAdGroups = (
  params: Paths.SearchTotalsForInstacartAdGroups.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForInstacartAdGroups.Responses.$200>(
    "instacart/ad_groups/totals",
    params
  );

/**
 * Get Instacart ad group
 */
export const getInstacartAdGroup = (
  params: Paths.GetInstacartAdGroup.PathParameters
) =>
  apicalls.get<Paths.GetInstacartAdGroup.Responses.$200>(
    `instacart/ad_groups/${params.id}`
  );

/**
 * instacart campaign collections
 */

/**
 * Create Instacart campaign collection
 */
export const createInstacartCampaignCollection = (
  params: Paths.CreateInstacartCampaignCollection.RequestBody
) =>
  apicalls.post<Paths.CreateInstacartCampaignCollection.Responses.$202>(
    "instacart/campaign_collections",
    params
  );

/**
 * Bulk add Instacart campaigns to Intacart campaign collections
 */
export const bulkAddCampaignsToInstacartCampaignCollections = (
  params: Paths.BulkAddCampaignsToInstacartCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkAddCampaignsToInstacartCampaignCollections.Responses.$202>(
    "instacart/campaign_collections/bulk_add",
    params
  );

/**
 * Bulk remove Instacart campaigns from Instacart campaign collections
 */
export const bulkRemoveCampaignsFromInstacartCampaignCollections = (
  params: Paths.BulkRemoveCampaignsFromInstacartCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.BulkRemoveCampaignsFromInstacartCampaignCollections.Responses.$202>(
    "instacart/campaign_collections/bulk_remove",
    params
  );

/**
 * Search Instacart campaign collections
 */
export const searchInstacartCampaignCollections = (
  params: Paths.SearchInstacartCampaignCollections.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartCampaignCollections.Responses.$200>(
    "instacart/campaign_collections/search",
    params
  );

/**
 * Destroy Instacart campaign collection
 */
export const destroyInstacartCampaignCollection = (
  params: Paths.DestroyInstacartCampaignCollection.PathParameters
) => apicalls.delete(`instacart/campaign_collections/${params.collection_id}`);

/**
 * Update Instacart campaign collection
 */
export const updateInstacartCampaignCollection = (
  params: Paths.UpdateInstacartCampaignCollection.PathParameters &
    Paths.UpdateInstacartAdGroupCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateInstacartCampaignCollection.Responses.$200>(
    `instacart/campaign_collections/${params.collection_id}`,
    params
  );

/**
 * instacart campaigns
 */

/**
 * List Instacart campaigns
 */
export const listInstacartCampaigns = (
  params: Paths.ListInstacartCampaigns.QueryParameters
) =>
  apicalls.get<Paths.ListInstacartCampaigns.Responses.$200>(
    "instacart/campaigns",
    params
  );

/**
 * Export Instacart campaigns
 */
export const exportInstacartCampaigns = (
  params: Paths.ExportInstacartCampaigns.RequestBody
) =>
  apicalls.post<Paths.ExportInstacartCampaigns.Responses.$202>(
    "instacart/campaigns/export",
    params
  );

/**
 * Search available Instacart campaigns
 */
export const searchInstacartCampaigns = (
  params: Paths.SearchInstacartCampaigns.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartCampaigns.Responses.$200>(
    "instacart/campaigns/search",
    params
  );

/**
 * Get search totals for Instacart campaigns
 */
export const searchTotalsForInstacartCampaigns = (
  params: Paths.SearchTotalsForInstacartCampaigns.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForInstacartCampaigns.Responses.$200>(
    "instacart/campaigns/totals",
    params
  );

/**
 * Get Instacart campaign
 */
export const getInstacartCampaign = (
  params: Paths.GetInstacartCampaign.PathParameters
) =>
  apicalls.get<Paths.GetInstacartCampaign.Responses.$200>(
    `instacart/campaigns/${params.id}`
  );

/**
 * instacart dashboard
 */

/**
 * Get Amazon cumulative spend
 */
export const getInstacartSpendCumulativeSpend = (
  params: Paths.GetInstacartSpendCumulativeSpend.QueryParameters
) =>
  apicalls.get<Paths.GetInstacartSpendCumulativeSpend.Responses.$200>(
    "dashboard/instacart/spend/cumulative",
    params
  );

/**
 * Get Amazon spend pacing
 */
export const getInstacartSpendPacing = (
  params: Paths.GetInstacartSpendPacing.RequestBody
) =>
  apicalls.post<Paths.GetInstacartSpendPacing.Responses.$200>(
    "dashboard/instacart/spend/pacing",
    params
  );

/**
 * Get Instacart performance by ad type statistics
 */
export const getInstacartPerformanceByAdTypes = (
  params: Paths.GetInstacartPerformanceByAdTypes.RequestBody
) =>
  apicalls.post<Paths.GetInstacartPerformanceByAdTypes.Responses.$200>(
    "dashboard/instacart/statistics/ad_types",
    params
  );

/**
 * Get Instacart performance by brand type statistics
 */
export const getInstacartPerformanceByBrandTypes = (
  params: Paths.GetInstacartPerformanceByBrandTypes.RequestBody
) =>
  apicalls.post<Paths.GetInstacartPerformanceByBrandTypes.Responses.$200>(
    "dashboard/instacart/statistics/brand_types",
    params
  );

/**
 * Get Instacart segmented performance statistics
 */
export const getInstacartSegmentedPerformanceStatistics = (
  params: Paths.GetInstacartSegmentedPerformanceStatistics.RequestBody
) =>
  apicalls.post<Paths.GetInstacartSegmentedPerformanceStatistics.Responses.$200>(
    "dashboard/instacart/statistics/segments",
    params
  );

/**
 * Get Instacart performance totals
 */
export const getInstacartPerformanceTotals = (
  params: Paths.GetInstacartPerformanceTotals.RequestBody
) =>
  apicalls.post<Paths.GetInstacartPerformanceTotals.Responses.$200>(
    "dashboard/instacart/statistics/totals",
    params
  );

/**
 * instacart keywords
 */

/**
 * Export Instacart Keywords
 */
export const exportInstacartKeywords = (
  params: Paths.ExportInstacartKeywords.RequestBody
) =>
  apicalls.post<Paths.ExportInstacartKeywords.Responses.$202>(
    "instacart/keywords/export",
    params
  );

/**
 * Search available Instacart keywords
 */
export const searchInstacartKeywords = (
  params: Paths.SearchInstacartKeywords.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartKeywords.Responses.$200>(
    "instacart/keywords/search",
    params
  );

/**
 * Get search totals for Instacart keywords
 */
export const searchTotalsForInstacartKeywords = (
  params: Paths.SearchTotalsForInstacartKeywords.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForInstacartKeywords.Responses.$200>(
    "instacart/keywords/totals",
    params
  );

/**
 * instacart products
 */

/**
 * List Instacart products
 */
export const listInstacartProducts = (
  params: Paths.ListInstacartProducts.QueryParameters
) =>
  apicalls.get<Paths.ListInstacartProducts.Responses.$200>(
    "instacart/products",
    params
  );

/**
 * Search Instacart products
 */
export const searchInstacartProducts = (
  params: Paths.SearchInstacartProducts.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartProducts.Responses.$200>(
    "instacart/products/search",
    params
  );

/**
 * Get search totals for Instacart products
 */
export const searchTotalsForInstacartProducts = (
  params: Paths.SearchTotalsForInstacartProducts.RequestBody
) =>
  apicalls.post<Paths.SearchTotalsForInstacartProducts.Responses.$200>(
    "instacart/products/totals",
    params
  );

/**
 * Get Instacart product
 */
export const getInstacartProduct = (
  params: Paths.GetInstacartProduct.PathParameters
) =>
  apicalls.get<Paths.GetInstacartProduct.Responses.$200>(
    `instacart/products/${params.upc}`
  );

/**
 * jobs
 */

// GET /jobs
// GET /jobs/{task_id}

/**
 * stats
 */

// POST /stats/amazon/profiles

/**
 * third_party
 */

// GET /instacart/integrations
// POST /instacart/integrations
// DELETE /instacart/integrations/{integration_id}
// PUT /instacart/integrations/{integration_id}
// GET /third_party/providers
// GET /third_party/providers/{provider_id}

/**
 * instacart ad group collection
 */

/**
 * Get Instacart ad group collection
 */
export const getInstacartAdGroupCollection = (
  params: Paths.GetInstacartAdGroupCollection.PathParameters
) =>
  apicalls.get<Paths.GetInstacartAdGroupCollection.Responses.$200>(
    `instacart/ad_group_collections/${params.collection_id}`
  );

/**
 * instacart campaign collection
 */

/**
 * Get Instacart campaign collection
 */
export const getInstacartCampaignCollection = (
  params: Paths.GetInstacartCampaignCollection.PathParameters
) =>
  apicalls.get<Paths.GetInstacartCampaignCollection.Responses.$200>(
    `instacart/campaign_collections/${params.collection_id}`
  );

/**
 * instacart product collections
 */

/**
 * Create Instacart product collection
 */
export const createInstacartProductCollection = (
  params: Paths.CreateInstacartProductCollection.RequestBody
) =>
  apicalls.post<Paths.CreateInstacartProductCollection.Responses.$202>(
    "instacart/product_collections",
    params
  );

/**
 * Bulk add Instacart products to Intacart product collections
 */
export const bulkAddProductsToInstacartProductCollections = (
  params: Paths.BulkAddProductsToInstacartProductCollections.RequestBody
) =>
  apicalls.post<Paths.BulkAddProductsToInstacartProductCollections.Responses.$202>(
    "instacart/product_collections/bulk_add",
    params
  );

/**
 * Bulk remove Instacart products from Instacart product collections
 */
export const bulkRemoveProductsFromInstacartProductCollections = (
  params: Paths.BulkRemoveProductsFromInstacartProductCollections.RequestBody
) =>
  apicalls.post<Paths.BulkRemoveProductsFromInstacartProductCollections.Responses.$202>(
    "instacart/product_collections/bulk_remove",
    params
  );

/**
 * Search Instacart product collections
 */
export const searchInstacartProductCollections = (
  params: Paths.SearchInstacartProductCollections.RequestBody
) =>
  apicalls.post<Paths.SearchInstacartProductCollections.Responses.$200>(
    "instacart/product_collections/search",
    params
  );

/**
 * Destroy Instacart product collection
 */
export const destroyInstacartProductCollection = (
  params: Paths.DestroyInstacartProductCollection.PathParameters
) => apicalls.delete(`instacart/product_collections/${params.collection_id}`);

/**
 * Update Instacart product collection
 */
export const updateInstacartProductCollection = (
  params: Paths.UpdateInstacartProductCollection.PathParameters &
    Paths.UpdateInstacartProductCollection.RequestBody
) =>
  apicalls.put<Paths.UpdateInstacartProductCollection.Responses.$200>(
    `instacart/product_collections/${params.collection_id}`,
    params
  );

/**
 * instacart product collection
 */

/**
 * Get Instacart product collection
 */
export const getInstacartProductCollection = (
  params: Paths.GetInstacartProductCollection.PathParameters
) =>
  apicalls.get<Paths.GetInstacartProductCollection.Responses.$200>(
    `instacart/product_collections/${params.collection_id}`
  );
