import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { listAmazonProfiles, listInstacartAccounts } from "helpers/api-calls";
import Dayjs, { Dayjs as DayjsType } from "dayjs";
// import qs from "qs";
import {
  DashboardAmazonProfile,
  DashboardInstacartProfile,
  Profile,
} from "helpers/interfaces";
import { AuthContext } from "store/auth";
import { Components } from "types/api";
import _ from "lodash";

type DashboardProfile = DashboardAmazonProfile | DashboardInstacartProfile;

const { useState } = React;

export const LOCAL_STORAGE_SELECTED_PROFILE_KEY =
  "LOCAL_STORAGE_SELECTED_PROFILE_KEY";

export type ProfileContextInterface = [
  {
    selectedProfile: DashboardProfile | null;
    selectedAccount: Components.Schemas.SparseUserMembership | null;
    loading: boolean;
    profiles: DashboardProfile[];
    accounts: Components.Schemas.SparseUserMembership[];
    range: {
      start: DayjsType;
      end: DayjsType;
    };
    comparedToRange: {
      start: DayjsType;
      end: DayjsType;
      segment: "daily" | "weekly" | "monthly" | "yearly";
    };
  },
  // The fetchProfiles method fetches the profiles from the api and sets them to context.
  () => void,
  // The setSelectedProfile method sets one of the profiles as selected in ctx
  (selectedProfile: DashboardProfile) => void,
  // The updateRange sets the new range. It is called from the date picker component.
  (
    dates: [DayjsType, DayjsType],
    comparedDates?: [
      DayjsType,
      DayjsType,
      ProfileContextInterface[0]["comparedToRange"]["segment"]
    ]
  ) => void,
  (selectedAccount: Components.Schemas.SparseUserMembership) => void
];

export interface WithProfiles<ProfileType = Profile> {
  selectedProfile: ProfileType;
  selectedAccount: Components.Schemas.SparseUserMembership;
  loading: boolean;
  profiles: DashboardProfile[];
  accounts: Components.Schemas.SparseUserMembership[];
  range: {
    start: DayjsType;
    end: DayjsType;
  };
  comparedToRange: {
    start: DayjsType;
    end: DayjsType;
    segment: "daily" | "weekly" | "monthly" | "yearly";
  };
}

const lastFullWeekStart = Dayjs().day(1).subtract(7, "days"); // Monday
const lastFullWeekEnd = Dayjs().day(1).subtract(1, "day"); // Sunday

const previousFullWeekStart = lastFullWeekStart.subtract(7, "days");
const previousFullWeekEnd = lastFullWeekEnd.subtract(7, "days");

export const initialState = {
  selectedProfile: null,
  selectedAccount: null,
  loading: true,
  profiles: [],
  accounts: [],
  range: {
    start: lastFullWeekStart,
    end: lastFullWeekEnd,
  },
  comparedToRange: {
    start: previousFullWeekStart,
    end: previousFullWeekEnd,
    segment: "daily" as ProfileContextInterface[0]["comparedToRange"]["segment"],
  },
};

export const ProfileContext = React.createContext<ProfileContextInterface>([
  initialState,
  () => {},
  () => {},
  () => {},
  () => {},
]);

const { Provider: ProfileContextProvider } = ProfileContext;

export const withProfiles = <P extends object>(
  Component: React.ComponentType<P & { profiles: WithProfiles }>
): React.FC<P> => (props) => (
  <ProfileContext.Consumer>
    {([profileData]) => {
      if (profileData.selectedProfile === null) {
        return null;
      }
      return <Component {...props} profiles={profileData as WithProfiles} />;
    }}
  </ProfileContext.Consumer>
);

/**
 *
 * The profile context stores profile and profile date range information. The wrapper sets
 * 4 values in an array: profileData, fetchProfiles, setSelectedProfile, & updateRange.
 * The profileData contains the selectedProfile, range, loading state of profiles,
 * and all profiles for the logged in account. The fetchProfiles method
 * fetches the profiles from the api and sets them to context.
 * The updateRange sets the new range. It is called from
 * the date picker component.
 */
export const ProfileContextProviderWrapper = withRouter(
  ({ children, location, history }) => {
    const [profileData, setProfileData] = useState<ProfileContextInterface[0]>(
      initialState
    );

    const [user] = useContext(AuthContext);

    useEffect(() => {
      if (user.authenticated) {
        fetchProfiles();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id]);

    const setSelectedProfile = (
      selectedProfile: ProfileContextInterface[0]["selectedProfile"]
    ) => {
      if (selectedProfile && profileData.selectedProfile?.profile_id) {
        window.localStorage.setItem("lastProfile", selectedProfile.profile_id);

        history.push(
          location.pathname.replace(
            `${profileData.selectedProfile.profile_id}`,
            `${selectedProfile.profile_id}`
          )
        );
      }

      setProfileData({
        ...profileData,
        selectedProfile,
      });
    };

    const setSelectedAccount = (
      selectedAccount: Components.Schemas.SparseUserMembership
    ) => {
      const filteredProfiles = _.filter(profileData.profiles, (p) =>
        _.includes(
          _.map(p.memberships, "account_id"),
          selectedAccount.account_id
        )
      );

      window.localStorage.setItem("lastAccount", selectedAccount.account_id);

      let selectedProfile: DashboardProfile;

      if (profileData.selectedProfile) {
        if (
          _.includes(
            _.map(filteredProfiles, "profile_id"),
            profileData.selectedProfile.profile_id
          )
        ) {
          selectedProfile = profileData.selectedProfile;
        } else if (filteredProfiles.length) {
          // eslint-disable-next-line prefer-destructuring
          selectedProfile = filteredProfiles[0];

          window.localStorage.setItem(
            "lastProfile",
            selectedProfile.profile_id
          );

          history.push(
            location.pathname.replace(
              `${profileData.selectedProfile.profile_id}`,
              `${selectedProfile.profile_id}`
            )
          );
        }
      }

      setProfileData({
        ...profileData,
        selectedProfile: selectedProfile!,
        selectedAccount,
      });
    };

    const fetchProfiles = () =>
      Promise.all([listAmazonProfiles(), listInstacartAccounts({})]).then(
        ([amazonRes, instacartRes]) => {
          if (!amazonRes.ok || !instacartRes.ok) return;

          let selectedProfile: DashboardProfile;
          let selectedAccount: Components.Schemas.SparseUserMembership;

          const profilesAmazon = amazonRes.json.data.map(
            (profile): DashboardAmazonProfile => ({
              ...profile,
              type: "amazon",
              has_brand_name: !!profile.account_brand_name,
              account_brand_name:
                profile.account_brand_name ||
                `Unnamed - ${profile.account_brand_id || ""}`,
            })
          );

          const profilesInsta = instacartRes.json.data.map(
            (profile): DashboardInstacartProfile => ({
              ...profile,
              type: "instacart",
              profile_id: profile.id,
              account_brand_name: profile.name || `Unamed - ${profile.id}`,
              has_brand_name: false,
            })
          );

          const profiles = [...profilesAmazon, ...profilesInsta];
          const lastProfile = window.localStorage.getItem("lastProfile");
          const lastAccount = window.localStorage.getItem("lastAccount");

          user.memberships.forEach((m) => {
            if (m.account_id && m.account_id === lastAccount) {
              selectedAccount = m;
            }
          });

          selectedAccount = selectedAccount! || user.memberships[0];

          const filteredProfiles = _.filter(profiles, (p) =>
            _.includes(
              _.map(p.memberships, "account_id"),
              selectedAccount!.account_id
            )
          );

          filteredProfiles.forEach((profile) => {
            if (
              profile.profile_id &&
              (location.pathname.match(`${profile.profile_id}`) ||
                `${profile.profile_id}` === lastProfile)
            ) {
              selectedProfile = profile;
            }
          });

          setProfileData({
            ...profileData,
            profiles,
            accounts: user.memberships,
            loading: false,
            selectedProfile: selectedProfile! || filteredProfiles[0],
            selectedAccount,
          });
        }
      );

    const setRanges = (
      range: [DayjsType, DayjsType],
      comparedTo?: [
        DayjsType,
        DayjsType,
        ProfileContextInterface[0]["comparedToRange"]["segment"]
      ]
    ) => {
      if (!range || range.length !== 2) {
        return;
      }

      // const q = qs.stringify({
      //   start: range[0].format("YYYY-MM-DD"),
      //   end: range[1].format("YYYY-MM-DD"),
      //   comparedToStart: comparedTo?.[0].format("YYYY-MM-DD"),
      //   comparedToEnd: comparedTo?.[1].format("YYYY-MM-DD"),
      //   comparedToSegment: comparedTo?.[2],
      // });

      // history.replace(`${location.pathname}?${q}`);

      setProfileData({
        ...profileData,
        range: {
          start: range[0],
          end: range[1],
        },
        comparedToRange:
          comparedTo && comparedTo.length === 3
            ? {
                start: comparedTo[0],
                end: comparedTo[1],
                segment: comparedTo[2],
              }
            : profileData.comparedToRange,
      });
    };

    // TODO: Remove this shim one automated optimizations is reworked for instacart
    const shim = { ...profileData };

    if (
      location.pathname.endsWith("automated-optimizations") &&
      profileData.selectedProfile?.type === "instacart"
    ) {
      // @ts-ignore
      shim.selectedProfile = {
        ...shim.selectedProfile,
        profile_id: "1483111654704198",
        // @ts-ignore
        type: "amazon",
      };
    }

    return (
      <ProfileContextProvider
        value={[
          shim,
          fetchProfiles,
          setSelectedProfile,
          setRanges,
          setSelectedAccount,
        ]}
      >
        {children}
      </ProfileContextProvider>
    );
  }
);
