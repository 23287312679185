import config from "config";
import qs from "qs";
import { getToken, deleteToken } from "helpers/tokens";
import { APIResponse, ErrorResponse } from "helpers/interfaces";

const rootApiCall = async <Res = any>(
  path: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: {}
): Promise<APIResponse<Res> | ErrorResponse> => {
  let token;

  if (path !== "auth/token/refresh" && path !== "auth/token/create") {
    token = await getToken();
  }

  const res = await fetch(`${config.apiServer}/${path}`, {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token
        ? {
            Authorization: `Token ${token}`,
          }
        : {}),
    },
  });

  if (!res.ok && res.status === 401) {
    deleteToken();

    if (!window.location.href.endsWith("/login")) {
      window.location.href = "/login";
    }
  }

  let json;

  try {
    json = await res.json();
  } catch (e) {
    json = undefined;
  }

  if (!res.ok) {
    return {
      ok: res.ok,
      status: res.status,
      json: {
        errors: json.errors,
      },
    };
  }

  return {
    ok: res.ok,
    status: res.status,
    json,
  };
};

const apicalls = {
  get: <Res = any>(path: string, querifyArgs?: any) =>
    rootApiCall<Res>(`${path}?${qs.stringify(querifyArgs)}`, "GET", undefined),
  post: <Res = any>(path: string, body: any) =>
    rootApiCall<Res>(path, "POST", body),
  put: <Res = any>(path: string, body: any) =>
    rootApiCall<Res>(path, "PUT", body),
  delete: (path: string) => rootApiCall(path, "DELETE"),
};

export default apicalls;
